import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import { Container, Resources, deviceMin, SubmitBtn } from "../components/Primitives"
import meta from "../../content/data/meta.json"
import ResourceHero from "../components/Resources/resourceHero"
import { deviceMax } from "../components/Primitives"
import styled from "styled-components"
import trainers from "../../static/images/trainer.webp"
import { useState } from "react"
import emailjs from "emailjs-com"
import Swal from "sweetalert2"

const TrainersPage = ({ data }) => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [contact, setContact] = useState("")
  const [address, setAddress] = useState("")
  const [city, setCity] = useState("")
  const [education, setEducation] = useState("")
  const [experience, setExperience] = useState("")
  const [expertise, setExpertise] = useState("")

  const handleNameChange = event => {
    setName(event.currentTarget.value)
  }
  const handleEmailChange = event => {
    setEmail(event.currentTarget.value)
  }
  const handleContactChange = event => {
    setContact(event.currentTarget.value)
  }
  const handleAddressChange = event => {
    setAddress(event.currentTarget.value)
  }
  const handleCityChange = event => {
    setCity(event.currentTarget.value)
  }
  const handleEducationChange = event => {
    setEducation(event.currentTarget.value)
  }
  const handleExperienceChange = event => {
    setExperience(event.currentTarget.value)
  }
  const handleExpertiseChange = event => {
    setExpertise(event.currentTarget.value)
  }
  function sendFeedback(templateId, variables) {
    emailjs
      .send("gmail", templateId, variables, "user_bQxczeMAkeLROJb3xebPP")
      .then(res => {
        console.log("Email successfully sent!")
        Swal.fire("Thanks!", "We will get back to you soon", "success")
      })
      .catch(err => {
        console.error(
          "Oh well, you failed. Here some thoughts on the error that occurred:",
          err
        )
        Swal.fire(
          "Oopss...",
          "Looks like you missed some thing, try submitting another form",
          "error"
        )
      })
  }
  const handleSubmit = e => {
    e.preventDefault()
    const templateId = "trainer"
    setName("")
    setEmail("")
    setContact("")
    setAddress("")
    setCity("")
    setEducation("")
    setExperience("")
    setExperience("")

    sendFeedback(templateId, {
      name: name,
      email: email,
      contact: contact,
      address: address,
      city: city,
      education: education,
      experience: experience,
      expertise: expertise
    })
  }
  return (
    <Layout>
      <SEO
        pathName="/trainers"
        title='Trainers'
        // keywords={keywords}
      />
      <ResourceHero
        color="rgb(184 133 142 / 52%)"
        title=""
        subTitle=""
        image={trainers}
      />

      <Body>
        <H1_Attn id="about">Register yourself as a Trainer</H1_Attn>
        <form className="was-validated" onSubmit={handleSubmit} >
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Enter full name"
                value={name}
                onChange={handleNameChange}
                required
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter email"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="contact">Contact No. <i>(Must be 11 digits)</i></label>
              <input
                type="tel"
                pattern="[0-9]{11}"
                className="form-control"
                id="contact"
                placeholder="Enter contact number"
                value={contact}
                onChange={handleContactChange}
                required
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="inputAddress">Address</label>
              <input
                type="text"
                className="form-control"
                id="inputAddress"
                placeholder="Enter address"
                value={address}
                onChange={handleAddressChange}
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="city">City</label>
              <input
                type="text"
                className="form-control"
                id="city"
                placeholder="Enter city"
                value={city}
                onChange={handleCityChange}
                required
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="education">Education</label>
              <input
                type="text"
                className="form-control"
                id="education"
                placeholder="Enter your qualification"
                value={education}
                onChange={handleEducationChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="experience">Experience <i>(No. of years)</i></label>
              <input
                type="number"
                min={0}
                className="form-control"
                id="experience"
                placeholder="Enter your experience"
                value={experience}
                onChange={handleExperienceChange}
                required
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="exampleFormControlSelect1">
                Area of expertise
              </label>
              <select
                className="form-control"
                id="exampleFormControlSelect1"
                value={expertise}
                onChange={handleExpertiseChange}
                required
              >
                <option value=''>Select your area of expertise</option>
                <option value="Soft skills">Soft skills</option>
                <option value="Technical trainers">Technical trainers</option>
                <option value="Others">Others</option>
              </select>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "5%",
              marginBottom: "10%"
            }}
          >
            <SubmitBtn
              style={{
                background: " #85083E",
                color: "white",
                padding: "1% 5%"
              }}
              type="submit"
              className="btn btn-primary"
            >
              <b>Send</b>
            </SubmitBtn>
          </div>
        </form>
      </Body>
    </Layout>
  )
}

export default TrainersPage

const H1_Attn = styled.h1`
  text-align: center;
  color: #85083e;
  margin: 50px 0;
  text-transform: uppercase;
`
const Body = styled.div`
  width: 60%;
  margin: auto;
  @media (max-width: 768px) {
    width: 90%;
  }
`
const Btn = styled.button`
  background: #b8858e;
`
